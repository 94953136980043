import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1185.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1185.000000) scale(0.100000,-0.100000)">
<path d="M6140 9798 c-63 -4 -151 -13 -195 -19 -44 -5 -111 -14 -150 -19 -38
-5 -88 -14 -110 -19 -22 -6 -98 -24 -170 -41 -71 -17 -162 -42 -202 -55 -39
-14 -77 -25 -83 -25 -7 0 -26 -6 -43 -13 -18 -8 -88 -36 -156 -62 -176 -70
-484 -227 -568 -291 -17 -13 -36 -24 -42 -24 -6 0 -11 -7 -11 -15 0 -13 40
-15 302 -15 l302 0 80 31 c45 18 90 35 101 39 51 19 113 43 143 56 18 8 41 14
52 14 11 0 43 9 72 20 111 43 432 104 633 120 247 21 325 22 495 11 159 -11
421 -44 439 -56 4 -2 32 -9 62 -14 122 -22 364 -91 446 -127 17 -8 37 -14 45
-14 7 0 22 -7 32 -15 11 -8 26 -15 33 -15 8 0 46 -15 86 -34 40 -18 83 -37 97
-41 14 -4 30 -11 35 -15 9 -7 52 -31 185 -103 117 -63 372 -239 440 -303 14
-13 59 -51 100 -85 122 -101 360 -347 360 -372 0 -9 9 -19 20 -22 11 -3 20
-10 20 -14 0 -4 8 -16 18 -27 10 -10 41 -50 70 -89 28 -38 58 -79 67 -90 28
-37 75 -112 151 -242 80 -137 117 -208 172 -338 95 -221 139 -357 186 -570 7
-33 18 -78 24 -100 40 -143 65 -470 58 -750 -9 -392 -74 -729 -210 -1095 -38
-102 -156 -353 -214 -453 -9 -16 -11 -49 -7 -117 8 -135 37 -390 44 -390 16 1
36 24 96 114 36 54 65 100 65 102 0 3 12 23 26 45 88 139 284 545 284 587 0 7
4 20 10 30 16 27 40 98 72 212 16 58 36 125 44 150 8 25 14 59 14 77 0 17 4
34 9 37 5 4 12 30 16 59 3 28 10 66 15 82 15 51 39 271 51 464 9 140 9 230 -1
380 -14 228 -56 546 -80 596 -5 11 -11 36 -13 55 -4 32 -26 117 -62 240 -23
77 -85 253 -94 264 -4 6 -15 34 -25 61 -10 28 -31 78 -47 111 -16 34 -29 66
-29 72 0 6 -3 12 -7 14 -5 2 -24 34 -43 72 -19 38 -52 99 -73 135 -49 85 -47
82 -107 176 -28 44 -56 89 -63 100 -7 11 -31 44 -55 75 -23 30 -46 60 -50 65
-51 69 -80 104 -101 127 -14 14 -44 48 -66 74 -52 61 -268 277 -330 329 -144
121 -160 133 -208 165 -20 14 -37 28 -37 33 0 4 -12 12 -26 17 -14 6 -28 19
-31 30 -3 11 -14 20 -24 20 -15 0 -119 62 -164 97 -5 5 -21 14 -35 21 -14 6
-47 25 -75 41 -27 17 -95 53 -150 81 -55 29 -104 56 -109 61 -6 5 -16 9 -22 9
-7 0 -42 14 -77 30 -36 17 -71 30 -79 30 -8 0 -18 4 -24 10 -11 11 -138 60
-174 67 -14 2 -34 8 -45 13 -62 26 -241 73 -385 100 -91 17 -185 36 -209 41
-107 24 -589 40 -791 27z"/>
<path d="M3640 8920 l0 -60 38 0 c48 0 157 -35 197 -63 77 -55 127 -133 158
-244 l22 -78 0 -1535 c0 -1242 -3 -1546 -13 -1595 -61 -271 -164 -393 -364
-430 l-38 -7 0 -54 0 -54 865 0 866 0 -3 57 -3 58 -55 7 c-54 8 -155 52 -195
86 -49 41 -109 141 -138 228 -42 131 -42 120 -42 1694 0 1519 2 1595 35 1658
4 10 18 37 30 62 57 119 185 192 365 209 l70 6 3 58 3 57 -901 0 -900 0 0 -60z"/>
<path d="M6034 8966 c-3 -7 -4 -33 -2 -57 l3 -44 70 -6 c319 -30 452 -181 386
-439 -25 -98 -30 -111 -89 -234 -36 -75 -200 -338 -222 -356 -3 -3 -35 -41
-70 -85 -35 -44 -67 -82 -70 -85 -3 -3 -44 -52 -91 -110 -46 -58 -86 -107 -89
-110 -3 -3 -33 -39 -67 -80 -67 -84 -101 -124 -154 -184 -19 -23 -62 -75 -94
-116 -33 -41 -66 -82 -75 -90 -8 -8 -33 -37 -55 -65 -21 -27 -42 -52 -45 -55
-3 -3 -32 -37 -64 -77 -37 -46 -55 -77 -50 -85 17 -27 142 -198 155 -211 8 -8
21 -25 29 -37 8 -12 32 -45 53 -73 21 -29 45 -61 52 -72 44 -61 127 -172 139
-186 7 -8 70 -100 140 -203 164 -243 169 -250 184 -259 6 -4 12 -12 12 -18 0
-11 81 -122 93 -127 4 -2 7 -8 7 -14 0 -6 20 -36 45 -67 25 -31 84 -105 131
-166 85 -108 177 -205 242 -255 17 -14 42 -33 54 -43 42 -34 206 -108 275
-123 135 -31 210 -34 760 -32 l558 3 0 50 0 50 -35 2 c-208 13 -411 73 -550
162 -69 45 -202 172 -260 251 -112 149 -192 257 -210 280 -10 14 -49 66 -86
115 -37 50 -79 104 -93 120 -15 17 -40 50 -56 75 -17 25 -50 70 -76 100 -25
30 -63 80 -83 110 -20 30 -56 78 -79 106 -23 28 -71 92 -107 142 -36 50 -150
206 -255 346 -104 140 -197 264 -205 276 -8 12 -48 64 -87 117 -40 53 -73 103
-73 113 0 14 42 75 95 137 5 6 24 28 40 49 104 128 318 386 350 420 5 6 41 46
80 90 194 219 409 434 560 559 33 28 62 53 65 56 9 11 154 114 204 146 186
117 395 183 584 183 l92 0 0 60 0 60 -980 0 c-827 0 -981 -2 -986 -14z"/>
<path d="M3678 8598 c-14 -18 -44 -52 -67 -74 -22 -23 -41 -45 -41 -49 0 -3
-6 -13 -14 -20 -7 -7 -42 -52 -77 -99 -35 -47 -67 -86 -71 -86 -4 0 -8 -5 -8
-12 0 -6 -11 -26 -25 -44 -14 -18 -25 -36 -25 -39 0 -4 -13 -24 -30 -45 -16
-21 -30 -43 -30 -48 0 -6 -6 -17 -14 -24 -7 -8 -21 -27 -30 -43 -9 -17 -37
-66 -61 -110 -25 -44 -53 -96 -61 -115 -9 -19 -32 -69 -51 -110 -19 -41 -38
-84 -43 -95 -38 -87 -49 -115 -60 -150 -6 -22 -18 -54 -26 -72 -8 -17 -14 -37
-14 -45 0 -8 -4 -18 -10 -24 -5 -5 -14 -30 -19 -54 -6 -25 -17 -70 -26 -100
-28 -92 -75 -302 -81 -360 -3 -30 -10 -64 -15 -75 -10 -22 -23 -151 -40 -403
-11 -163 -9 -208 21 -585 5 -66 13 -124 18 -130 5 -7 12 -39 15 -72 10 -96 57
-298 93 -398 8 -20 14 -47 14 -60 0 -12 7 -35 15 -51 8 -15 15 -34 15 -42 0
-7 6 -28 14 -46 8 -18 26 -64 42 -103 41 -104 126 -295 155 -348 6 -10 19 -36
31 -58 11 -21 24 -42 29 -45 5 -3 9 -13 9 -23 0 -10 7 -24 16 -31 8 -8 22 -27
29 -44 18 -37 31 -59 68 -107 15 -20 27 -40 27 -44 0 -4 46 -68 103 -142 56
-75 107 -143 113 -152 43 -66 403 -426 500 -501 188 -146 265 -200 426 -301
62 -40 96 -59 208 -118 8 -4 26 -14 40 -21 64 -35 242 -117 285 -133 22 -8 72
-26 110 -40 102 -38 363 -117 415 -127 25 -5 88 -17 140 -28 169 -35 586 -78
603 -62 5 4 23 4 40 0 117 -26 671 29 903 91 43 11 90 23 104 25 38 8 214 64
320 102 221 79 600 267 600 296 0 9 -97 4 -260 -14 -47 -5 -184 -9 -305 -9
l-221 -1 -124 -40 c-69 -23 -145 -45 -170 -51 -55 -11 -204 -39 -245 -45 -16
-3 -68 -11 -115 -19 -121 -20 -445 -28 -650 -15 -184 11 -343 30 -356 42 -4 5
-19 8 -33 8 -37 0 -161 27 -272 61 -53 16 -105 29 -116 29 -10 0 -23 4 -29 8
-5 5 -49 23 -99 41 -175 63 -287 112 -420 184 -134 73 -149 82 -163 94 -7 7
-19 13 -25 13 -7 0 -25 11 -41 25 -16 14 -33 25 -37 25 -9 0 -95 57 -118 78
-8 8 -33 25 -55 40 -23 14 -45 31 -51 37 -5 6 -30 27 -55 46 -25 18 -50 38
-56 44 -6 5 -41 37 -78 70 -148 132 -298 293 -415 445 -69 90 -212 303 -252
375 -95 173 -116 215 -138 265 -54 128 -62 146 -71 161 -19 35 -91 256 -124
381 -47 180 -63 258 -76 358 -6 47 -16 119 -22 160 -7 43 -11 198 -11 365 1
260 4 306 27 450 29 183 50 293 58 315 4 8 16 53 28 99 21 80 62 213 79 256 5
11 19 47 31 80 28 75 58 142 122 270 43 88 65 129 120 222 81 138 226 343 296
416 14 15 17 36 15 113 -2 111 -24 184 -66 220 -38 32 -60 30 -89 -8z"/>
<path d="M7430 7478 c-113 -7 -297 -35 -375 -58 -184 -54 -297 -97 -415 -158
-94 -49 -286 -173 -339 -220 l-35 -31 37 -48 c20 -26 40 -52 44 -58 5 -5 46
-62 93 -125 99 -134 94 -127 123 -162 12 -14 47 -61 76 -102 30 -42 60 -76 67
-76 7 0 18 25 24 58 7 31 23 95 36 142 14 47 29 99 33 115 61 217 234 445 401
527 41 20 91 39 110 43 19 4 40 11 45 15 6 4 67 13 136 19 130 12 348 2 413
-19 17 -5 65 -19 106 -31 68 -19 227 -91 240 -107 3 -4 25 -20 50 -36 100 -65
245 -221 341 -366 91 -138 185 -317 274 -520 l41 -95 61 -3 60 -3 12 143 c7
79 18 217 26 308 7 91 18 210 24 265 18 178 20 223 8 238 -7 7 -37 30 -69 50
-146 95 -305 164 -473 206 -49 12 -106 27 -125 32 -165 46 -737 77 -1050 57z"/>
<path d="M5616 5895 c-8 -21 -34 -181 -46 -285 -12 -97 -12 -443 0 -540 20
-170 38 -259 94 -461 47 -168 168 -417 282 -581 79 -113 212 -263 300 -338
116 -99 142 -120 152 -120 6 0 12 -4 14 -9 6 -16 147 -101 259 -158 190 -94
439 -166 674 -195 124 -15 511 -14 702 1 294 23 577 88 778 176 149 66 298
147 322 176 12 14 7 114 -17 334 -10 97 -19 196 -41 470 -6 72 -15 135 -20
141 -14 17 -100 12 -110 -6 -4 -8 -24 -64 -43 -125 -41 -131 -53 -160 -117
-288 -141 -280 -372 -515 -634 -645 -70 -35 -108 -49 -251 -93 -139 -43 -506
-38 -593 7 -14 8 -32 14 -40 14 -7 0 -45 17 -84 38 -176 92 -322 294 -404 556
-56 183 -50 158 -81 341 -20 113 -42 280 -48 357 l-6 76 -78 46 c-111 66 -234
176 -365 326 -37 43 -166 209 -180 231 -5 9 -32 45 -58 80 -45 58 -188 261
-272 384 -19 28 -45 63 -58 79 -21 26 -24 27 -31 11z"/>
<path d="M3995 4732 c-25 -15 -263 -141 -373 -196 l-32 -17 50 -98 c51 -100
78 -134 117 -145 27 -9 310 134 361 181 32 30 34 37 29 75 -7 51 -87 212 -108
216 -7 1 -27 -6 -44 -16z m45 -157 c0 -18 -44 -46 -178 -115 -93 -47 -105 -51
-119 -37 -14 13 -8 19 48 50 242 131 249 134 249 102z"/>
<path d="M4048 4236 c-114 -67 -210 -125 -214 -129 -7 -7 8 -32 49 -81 l24
-30 37 24 c35 21 39 21 54 6 15 -15 14 -18 -17 -50 l-34 -35 39 -50 c22 -28
42 -50 45 -51 4 0 32 28 64 63 32 34 96 103 144 152 47 50 100 106 118 125
l32 35 -55 73 c-30 39 -60 72 -67 71 -7 0 -105 -55 -219 -123z m110 -103 c-64
-64 -69 -67 -85 -51 -9 10 -15 19 -12 22 8 8 153 96 158 96 3 0 -24 -30 -61
-67z"/>
<path d="M4416 4094 l-29 -35 28 -28 27 -29 -64 -69 c-35 -37 -96 -103 -136
-145 -40 -42 -72 -81 -72 -85 0 -5 19 -28 43 -51 l43 -43 115 123 c63 68 127
136 142 153 l26 29 29 -27 28 -27 38 39 37 38 -27 22 c-16 12 -63 56 -106 97
-43 41 -81 74 -85 73 -5 0 -21 -16 -37 -35z"/>
<path d="M4600 3679 c-91 -105 -166 -194 -167 -197 -3 -8 92 -72 106 -72 5 0
19 14 31 30 23 32 41 38 58 17 8 -10 6 -22 -8 -45 -11 -18 -20 -36 -20 -41 0
-4 24 -24 54 -44 l53 -35 46 96 c25 53 75 160 111 236 36 76 66 140 66 141 0
3 -159 105 -162 105 -2 -1 -77 -86 -168 -191z m129 -73 c-22 -42 -44 -76 -49
-76 -18 0 -28 25 -17 41 30 40 100 119 104 116 2 -3 -15 -39 -38 -81z"/>
<path d="M5155 3622 c-21 -53 -128 -341 -135 -362 -4 -14 -15 -42 -24 -63
l-17 -38 43 -18 c85 -37 80 -39 112 48 37 106 44 115 79 102 42 -16 92 -13
115 7 26 21 72 142 72 186 0 37 -36 78 -85 96 -158 61 -153 59 -160 42z m128
-133 c5 -9 2 -30 -12 -61 -15 -36 -26 -48 -42 -48 -16 0 -20 5 -16 18 36 111
48 126 70 91z"/>
<path d="M5505 3501 c-5 -16 -52 -237 -81 -381 -8 -41 -17 -82 -20 -91 -4 -13
14 -20 97 -38 57 -12 106 -18 110 -14 4 4 10 27 14 49 6 41 6 42 -29 48 -19 4
-37 10 -39 14 -2 4 15 95 38 202 23 107 41 197 39 199 -2 2 -30 9 -63 16 -54
11 -61 11 -66 -4z"/>
<path d="M6305 3471 c-81 -36 -88 -65 -64 -252 10 -79 25 -163 34 -186 31 -81
123 -108 186 -56 21 18 34 24 37 15 2 -6 16 -12 32 -12 58 0 58 -1 39 133 -10
67 -19 128 -19 135 0 14 -10 13 -118 -5 -31 -5 -33 -8 -30 -42 2 -20 7 -34 12
-33 15 6 26 -22 26 -70 0 -48 -13 -69 -33 -49 -12 12 -59 330 -51 343 11 18
34 6 40 -19 12 -58 22 -78 35 -76 8 1 36 4 62 8 l49 6 -7 42 c-4 23 -15 56
-24 72 -34 61 -128 82 -206 46z"/>
<path d="M5797 3453 c-3 -10 -9 -108 -13 -218 -7 -227 -3 -249 59 -278 84 -40
174 -27 213 30 23 35 27 65 40 334 7 142 11 134 -72 138 l-52 2 -6 -78 c-3
-43 -9 -140 -12 -216 -4 -75 -11 -140 -16 -143 -27 -17 -30 19 -20 223 6 114
10 208 9 209 -1 1 -30 5 -64 9 -54 6 -62 5 -66 -12z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
